exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blogdetail-jsx": () => import("./../../../src/pages/blogdetail.jsx" /* webpackChunkName: "component---src-pages-blogdetail-jsx" */),
  "component---src-pages-case-detail-jsx": () => import("./../../../src/pages/caseDetail.jsx" /* webpackChunkName: "component---src-pages-case-detail-jsx" */),
  "component---src-pages-case-jsx": () => import("./../../../src/pages/case.jsx" /* webpackChunkName: "component---src-pages-case-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contactUs.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-documentation-jsx": () => import("./../../../src/pages/documentation.jsx" /* webpackChunkName: "component---src-pages-documentation-jsx" */),
  "component---src-pages-drop-menu-jsx": () => import("./../../../src/pages/dropMenu.jsx" /* webpackChunkName: "component---src-pages-drop-menu-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-product-list-jsx": () => import("./../../../src/pages/productList.jsx" /* webpackChunkName: "component---src-pages-product-list-jsx" */),
  "component---src-pages-resources-faq-jsx": () => import("./../../../src/pages/resourcesFaq.jsx" /* webpackChunkName: "component---src-pages-resources-faq-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-solutions-detail-jsx": () => import("./../../../src/pages/solutionsDetail.jsx" /* webpackChunkName: "component---src-pages-solutions-detail-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */)
}

